import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {initializeApp} from "firebase/app";
import { doc, onSnapshot, setDoc,updateDoc, deleteField, getDoc } from "firebase/firestore";
import { getFirestore, serverTimestamp} from "firebase/firestore";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import firebase from "firebase/app";



const firebaseConfig = {
    apiKey: "AIzaSyC6_bdIGk8MIAYaOIyUKhq_O246ieeSrgo",
    authDomain: "moneygo-b335c.firebaseapp.com",
    databaseURL: "https://moneygo-b335c-default-rtdb.firebaseio.com",
    projectId: "moneygo-b335c",
    storageBucket: "moneygo-b335c.appspot.com",
    messagingSenderId: "425049311811",
    appId: "1:425049311811:web:6401c9da55b07151d162f6"
  };

  const app = initializeApp(firebaseConfig);
 

createApp(App).use(store).use(router).mount('#app')






const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Mensaje recivido. ', payload);
  let notificationTitle = '';
    let badge = ''
    if(payload.notification){
      if (payload.notification.title == 'win') {
        notificationTitle = 'Haz ganado un reto '
        badge = 'badgewin-128x128.png'
      }else if(payload.notification.title == 'lost'){
        notificationTitle = 'Haz perdido un reto'
        badge = 'badgeprincipal-128x128.png'
      }else{
        notificationTitle = payload.notification.title
        badge = 'badge-128x128.png'
      }

      return store.commit('noti',{
        title: notificationTitle    ,
        body: payload.notification.body,
        icon: payload.notification.image,
        badge: require(`./assets/${badge}`),
        tag: 123454
      });
    }
    


  // ...
});















  getToken(messaging, { vapidKey: 'BLssZFsf_C8lYwNPSUiDhxGOaCYw5q4c_K5kC7I_mElBqmSNB6ORkf5TLXD9xq6JnBk1hCTrM4HhAioilGI2BzE' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      
      sendToken(currentToken)
      // ...
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });




async function sendToken(payload) {
  const uid =  store.state.user.uid
  const db = getFirestore()
  try {
  console.log(payload);
   await setDoc(doc(db, "tokens", uid),{
    token: payload
   });

    
  } catch (error) {
    
  }
}






function invited() {
  //notificar quien me ha invitado
const db = getFirestore()
const uid =  store.state.user.uid

if(localStorage.getItem('dataSesion')){
  if (uid) {
    const unsub = onSnapshot(doc(db,'Inviteds',uid),(doc) => {
      const data = doc.data()
          if (data) {
            for(i in data){
             
              if (!data[i].active && !data[i].acepted && data[i].uid != uid) {
                
                if(data[i].otherFriends.length>1){
                  
                }else{
                  
                }
                
              }
              
            }
            
          }
          
      });
  }
  
}
}


const db = getFirestore()
const uid =  store.state.user.uid
let idReto = ''
let datos = ''


if (uid) {
  const unsub = onSnapshot(doc(db,'localNoti',uid),(doc) => {
  
  
  
    for(i in doc.data()){
      console.log(doc.data()[i]);
      idReto = i
      datos = doc.data()[i].status;
      if (datos) {
        deleteDataLocalNoti()
      }

      notisend(i,doc.data()[i].status,doc.data()[i].other)
    } 
        
  });

}
    

  
function deleteDataLocalNoti(){

    setTimeout(() => {
      console.log('ha empezado');
      updateDoc(doc(db, "localNoti", uid),{
        [idReto]: deleteField()
      });
    store.state.contentLocalNoti = []
    }, 6000);

}

async function notisend(i,status,otherUserId){
  try {

                const uid =  store.state.user.uid
                const token =  store.state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/inviteds/${otherUserId}/user/${uid}/${i}.json?auth=${token}`)
                const dataDb = await res.json()
                if (dataDb) {
                  console.log('entro en invitaciones');
            
                    store.commit('localNoti',{
                      idReto: i,
                      result: status,
                      valor: dataDb.valor,
                      player: dataDb.player     
                    });
                  
                }else{
                  const resdata = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/retos/${uid}/${i}.json?auth=${token}`)
                  const dataDbdata = await resdata.json()
                  if (dataDbdata) {
                    
                    console.log('entro en retos publicos');
                    console.log();
                      store.commit('localNoti',{
                        idReto: i,
                        result: status,
                        valor: dataDbdata.valor,
                        player: dataDbdata.player     
                      });
                    
                  }
                }
                

   

  } catch (error) {
    
  }
}
  





function miles(valor) {
  let numero = valor
  let partesNumero = numero.toString().split('.');
  partesNumero[0] = partesNumero[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return partesNumero.join('.');    


}

//notificar quien acepto mi invitación


 

const interval = setInterval(() => {
  if (Notification.permission === 'granted'){
    if(localStorage.getItem('dataSesion')){
      invited()
      
      clearInterval(interval)
    }
    
  }
}, 3000);

 



async function changeStatus(id){
  console.log('empezamos');
  const datos = JSON.parse(localStorage.getItem("misRetos"))
  
      const misRetos = []
      if (datos) {
            for(let i in datos){
              console.log(datos[i].id);
              console.log(id);
              if (datos[i].id === id) {
                datos[i].status = true
              }
              misRetos.push(datos[i])
            }  
          
            localStorage.setItem('misRetos',JSON.stringify(misRetos))
              try {
                const uid =  store.state.user.uid
                const token =  store.state.user.token
                const res = await fetch(`https://moneygo-b335c-default-rtdb.firebaseio.com/mylocalstorage/${uid}/misRetos.json?auth=${token}`,{
                       method: 'PUT',
                       body: JSON.stringify(misRetos)   
                })
                const dataDb = await res.json()
                console.log(dataDb);
              } catch (error) {
                console.log(error);
              }
          
 
      }else{
        
      }
}






//send hours



///notificar quien acepto mi reto


//verificar funcionalidades de contacto en el telegono primeor en el computador 

// Hace una solicitud de almacenamiento persistente al sitio



