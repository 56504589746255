
<template>
  <router-view/>
</template>



<script>
  
  import {mapActions} from "vuex";
  import router from "../src/router";
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { getFirestore, serverTimestamp} from "firebase/firestore";
  import { doc, setDoc, getDoc, updateDoc, onSnapshot, deleteField, orderBy } from "firebase/firestore";
 
  import 'prevent-pull-refresh';
 
  export default {
    components: {
      
    },
    methods:{
      ...mapActions(['loadData','loadDataUser']),
       get(){
            const auth = getAuth();
            
        },

        removeaud(){
            const aud = document.getElementsByTagName('audio')
        if (aud.length >=2) {
            for(i in aud){
                if (aud[i].tagName) {
                    aud[i].remove()
                }
              
            }
          
        }
        },

        
        
    },

    computed:{
      
     
    },
    created(){
      this.loadDataUser();
      this.get()
     
      this.removeaud()
      window.onload = function() {
      document.addEventListener("contextmenu", function(e){
     e.preventDefault();
     
  }, false);
} 
   
    }
  };
</script>
  
<style>
*{
  margin: 0;
}

#app {
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #161616;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  touch-action: pan-x pan-y;
  height: 100% 
}



</style>
