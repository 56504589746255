import { createRouter, createWebHistory } from 'vue-router'
import  store  from '../store';



 


const router = createRouter({
  history: createWebHistory(),
  routes:  [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'nofound',
      component: () => import('/')
    },
    
   
    
  ]
  
})



export default router
